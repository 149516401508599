<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row>
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="weekly-statistics"
              :show-labels="false"
              :hide-custom-filters="true"
              :filters="filteringPanel.filters"
              :loaded="filteringPanel.loaded"
              @search="getData"
              @change="filterData"
              :load-dictionaries="loadDictionaries"
              @dict-loaded="onFilteringPanelLoad"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            Current period: {{ currentPeriod }}
            <br />
            Previous period:
            {{ previousPeriod }}
          </b-col>
        </b-row>
        <b-row v-if="dataTable.visible">
          <b-col>
            <weekly-statistics-table
              ref="weekly-statistics"
              @cell-click="onCellClick"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import WeeklyStatisticsTable from "@/views/UserManagement/WeeklyStatisticsTable";

export default {
  name: "Products",
  components: {
    FilteringPanel,
    WeeklyStatisticsTable
  },
  data: function() {
    return {
      rawData: [],
      dataTable: {
        visible: true
      },
      currentPeriod: "",
      previousPeriod: "",
      filteringPanel: {
        loaded: false,
        selected: {},
        districts: [],

        filters: [
          {
            type: "select",
            dataType: "string",
            title: "User Name",
            name: "user_name",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            title: "Role",
            name: "role",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "Last 30 days",
            title: "Period",
            name: "ws-period"
          }
        ]
      }
    };
  },
  computed: {},
  mounted() {
    // this.getData();
  },
  methods: {
    async onCustomFilterSelected() {},
    onCellClick(event) {
      console.log("onCellClick", event);

      if (event.name == "PastActivities") {
        this.$form.prepareDrillDownFiltersWeeklyStats(
          this.$refs.filteringPanel,
          event
        );

        //open new page
        //this.$router.push({ name: "All activities" });

        let routeData = this.$router.resolve({
          name: "All activities"
        });
        window.open(routeData.href, "_blank");
      }
      if (event.name == "CurrentActivities") {
        this.$form.prepareDrillDownFiltersWeeklyStats(
          this.$refs.filteringPanel,
          event
        );
        let routeData = this.$router.resolve({
          name: "All activities"
        });
        window.open(routeData.href, "_blank");
      }
      if (event.name.toLowerCase().includes("tasks")) {
        this.$form.prepareDrillDownFiltersWeeklyStats(
          this.$refs.filteringPanel,
          event
        );

        let routeData = this.$router.resolve({
          name: "Tasks"
        });
        window.open(routeData.href, "_blank");
      }
    },
    async loadDictionaries() {
      let self = this;

      const roles = async () => {
        if (this.filteringPanel.filters.find(f => f.name === "role")) {
          this.$api.get("roles").then(response => {
            this.filteringPanel.filters.find(f => f.name === "role").options = [
              ...response.map(u => ({
                id: u.id,
                label: u.rolename
              }))
            ];
          });
        }
      };

      const users = async () => {
        if (this.filteringPanel.filters.find(f => f.name === "user_name")) {
          this.$api.get("dictionaries/users").then(response => {
            this.filteringPanel.filters.find(
              f => f.name === "user_name"
            ).options = [
              ...response.map(u => ({
                id: u.id,
                label: u.full_name
              }))
            ];
          });
        }
      };

      Promise.all([users(), roles()]).then(() => {
        self.filteringPanel.loaded = true;
      });
    },
    async onFilteringPanelLoad() {
      await this.getData();

      this.filterData(this.$refs.filteringPanel.selected);
    },
    async getData() {
      //this.dataTable.isLoading = true;
      //this.dataTable.dataSet = [];
      let fp = this.$refs.filteringPanel;
      let f = this.$refs.filteringPanel.selected;

      let payload = {
        user_name: f.user_name ? f.user_name.map(i => i.label) : [],

        user_role: f.user_role ? f.user_role.map(i => i.label) : [],
        period: f["ws-period"]
      };

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }

      console.log("getData.payload", JSON.stringify(payload));

      await this.$refs["weekly-statistics"].getData(payload);

      this.currentPeriod =
        this.$refs["weekly-statistics"].rawData[0].tws +
        " - " +
        this.$refs["weekly-statistics"].rawData[0].twe;
      this.previousPeriod =
        this.$refs["weekly-statistics"].rawData[0].lws +
        " - " +
        this.$refs["weekly-statistics"].rawData[0].lwe;

      this.filterData();
    },
    filterData: function(e) {
      if (e) this.filteringPanel.selected = e;

      let selected = this.filteringPanel.selected;

      if (!this.$refs["weekly-statistics"].rawData.length) return;

      let filteredData = this.$refs["weekly-statistics"].rawData;

      filteredData = filteredData.filter(i =>
        selected.user_name && selected.user_name.length > 0
          ? selected.user_name.find(s => i["User name"].includes(s.label))
          : true
      );

      filteredData = filteredData.filter(i =>
        selected.role && selected.role.length > 0
          ? selected.role.find(s => i["User role"].includes(s.label))
          : true
      );

      this.$refs["weekly-statistics"].dataTable.dataSet = filteredData;
    }
  },
  watch: {}
};
</script>

<style></style>
