var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "pb-3",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "weekly-statistics",
      "show-labels": false,
      "hide-custom-filters": true,
      "filters": _vm.filteringPanel.filters,
      "loaded": _vm.filteringPanel.loaded,
      "load-dictionaries": _vm.loadDictionaries
    },
    on: {
      "search": _vm.getData,
      "change": _vm.filterData,
      "dict-loaded": _vm.onFilteringPanelLoad
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_vm._v(" Current period: " + _vm._s(_vm.currentPeriod) + " "), _c('br'), _vm._v(" Previous period: " + _vm._s(_vm.previousPeriod) + " ")])], 1), _vm.dataTable.visible ? _c('b-row', [_c('b-col', [_c('weekly-statistics-table', {
    ref: "weekly-statistics",
    on: {
      "cell-click": _vm.onCellClick
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }