<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`custom_table_weekly_statistics`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
      <slot name="Past activities" slot="Past activities" slot-scope="props">
        <div @click="onPastActivitiesClick(props.row)" class="drilldown">
          {{
            props.row["Past activities"] == "0"
              ? ""
              : props.row["Past activities"]
          }}
        </div>
      </slot>
      <slot
        name="Current activities"
        slot="Current activities"
        slot-scope="props"
      >
        <div @click="onCurrentActivitiesClick(props.row)" class="drilldown">
          {{
            props.row["Current activities"] == "0"
              ? ""
              : props.row["Current activities"]
          }}
        </div>
      </slot>
      <slot
        name="Past tasks completed"
        slot="Past tasks completed"
        slot-scope="props"
      >
        <div @click="onPastTasksCompletedClick(props.row)" class="drilldown">
          {{
            props.row["Past tasks completed"] == "0"
              ? ""
              : props.row["Past tasks completed"]
          }}
        </div>
      </slot>
      <slot
        name="Current tasks completed"
        slot="Current tasks completed"
        slot-scope="props"
      >
        <div @click="onCurrentTasksCompletedClick(props.row)" class="drilldown">
          {{
            props.row["Current tasks completed"] == "0"
              ? ""
              : props.row["Current tasks completed"]
          }}
        </div>
      </slot>
      <slot name="Open tasks" slot="Open tasks" slot-scope="props">
        <div @click="onOpenTasksClick(props.row)" class="drilldown">
          {{ props.row["Open tasks"] == "0" ? "" : props.row["Open tasks"] }}
        </div>
      </slot>
    </table-custom>
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";

export default {
  name: "WeeklyStatisticsTable",
  components: {
    TableCustom
  },
  data: function() {
    return {
      rawData: {},
      dataTable: {
        //loading: false,
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "User name",
            "User role",
            "Past activities",
            "Current activities",
            "Past tasks completed",
            "Current tasks completed",
            "Open tasks"
          ],
          filterable: [
            "ID",
            "User name",
            "User role",
            "Past activities",
            "Current activities",
            "Past tasks completed",
            "Current tasks completed",
            "Open tasks"
          ],
          slots: [
            "Past activities",
            "Current activities",
            "Past tasks completed",
            "Current tasks completed",
            "Open tasks"
          ],
          footerHeadings: false,
          perPage: 50,
          disablePerPageDropdown: false,
          showActions: false
        }
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onFilter() {},

    async getData(payload) {
      let self = this;

      this.dataTable.isLoading = true;

      //  this.dataTable.dataSet = [];

      let response = [];

      console.log("weeklystatisticstable.payload", JSON.stringify(payload));

      try {
        response = await this.$api.post(
          "users/weekly-statistics-period",
          payload
        );

        self.dataTable.isLoading = false;

        self.dataTable.dataSet = response;

        self.rawData = response;

        console.log("response", response);

        if (response.length === 0) return;

        self.$emit("loaded", self.dataTable.dataSet.length);
      } catch (error) {
        console.log(error);
        self.dataTable.isLoading = false;
        self.$form.msgBoxOk("Error occured");
      }
    },
    onRowClick(event) {
      this.$emit("row-click", event);
    },
    onPastActivitiesClick(event) {
      this.$emit("cell-click", { name: "PastActivities", ...event });
    },
    onCurrentActivitiesClick(event) {
      this.$emit("cell-click", { name: "CurrentActivities", ...event });
    },
    onPastTasksCompletedClick(event) {
      this.$emit("cell-click", { name: "PastTasks", ...event });
    },
    onCurrentTasksCompletedClick(event) {
      this.$emit("cell-click", { name: "CurrentTasks", ...event });
    },
    onOpenTasksClick(event) {
      this.$emit("cell-click", { name: "OpenTasks", ...event });
    }
  },
  watch: {}
};
</script>

<style scoped>
.drilldown {
  width: 100%;
  text-align: center;
  text-decoration: underline;
}
</style>
