var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "custom_table_weekly_statistics",
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    scopedSlots: _vm._u([{
      key: "Past activities",
      fn: function fn(props) {
        return _vm._t("Past activities", function () {
          return [_c('div', {
            staticClass: "drilldown",
            on: {
              "click": function click($event) {
                return _vm.onPastActivitiesClick(props.row);
              }
            }
          }, [_vm._v(" " + _vm._s(props.row["Past activities"] == "0" ? "" : props.row["Past activities"]) + " ")])];
        });
      }
    }, {
      key: "Current activities",
      fn: function fn(props) {
        return _vm._t("Current activities", function () {
          return [_c('div', {
            staticClass: "drilldown",
            on: {
              "click": function click($event) {
                return _vm.onCurrentActivitiesClick(props.row);
              }
            }
          }, [_vm._v(" " + _vm._s(props.row["Current activities"] == "0" ? "" : props.row["Current activities"]) + " ")])];
        });
      }
    }, {
      key: "Past tasks completed",
      fn: function fn(props) {
        return _vm._t("Past tasks completed", function () {
          return [_c('div', {
            staticClass: "drilldown",
            on: {
              "click": function click($event) {
                return _vm.onPastTasksCompletedClick(props.row);
              }
            }
          }, [_vm._v(" " + _vm._s(props.row["Past tasks completed"] == "0" ? "" : props.row["Past tasks completed"]) + " ")])];
        });
      }
    }, {
      key: "Current tasks completed",
      fn: function fn(props) {
        return _vm._t("Current tasks completed", function () {
          return [_c('div', {
            staticClass: "drilldown",
            on: {
              "click": function click($event) {
                return _vm.onCurrentTasksCompletedClick(props.row);
              }
            }
          }, [_vm._v(" " + _vm._s(props.row["Current tasks completed"] == "0" ? "" : props.row["Current tasks completed"]) + " ")])];
        });
      }
    }, {
      key: "Open tasks",
      fn: function fn(props) {
        return _vm._t("Open tasks", function () {
          return [_c('div', {
            staticClass: "drilldown",
            on: {
              "click": function click($event) {
                return _vm.onOpenTasksClick(props.row);
              }
            }
          }, [_vm._v(" " + _vm._s(props.row["Open tasks"] == "0" ? "" : props.row["Open tasks"]) + " ")])];
        });
      }
    }], null, true)
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }